import React from "react"

// Components
import Seo from "../components/seo"
import Header from "../components/header"
import Hero from "./screens/respiratory-health-test/hero"
import FormLanding from "./screens/respiratory-health-test/form-landing"
import BodyLanding from "./screens/respiratory-health-test/body-landing"

const MinutesMatter = () => {
	return (
		<main>
			<Header />
			<Hero />
			<FormLanding />
			<BodyLanding />
		</main>
	)
}

export default MinutesMatter

export const Head = () => (
	<Seo
	  title="Respiratory Health Test "
	  description="In less than 30 minutes, the instrument free Respiratory Health Test provides lab accurate results to confidently disposition patients."
	  recaptcha
	/>
  )
